<template>
    <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
        <form @submit.prevent="$emit('submit', { handleSubmit, form })" ref="form" v-loading="submitting"
            class="custom-form-admin">
            <div class="mt-3 mb-4 cardStyle px-4 pt-4 pb-0">
                <div class="row mt-2">
                    <div class="col-12 col-md-12">
                        <div class="d-flex justify-content-start align-items-center status">
                            <p class="status-text">Active/Inactive</p>
                            <base-switch class="status-toggle super-admin" onText="" offText="" ref="templateSwitcher"
                                v-model="form.status">
                            </base-switch>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <base-input name="title" label="Title" v-model="form.title"
                                    :rules="{ custom_required: form.image ? false : true || shopImageUrl ? true : false }"
                                    placeholder="Title" maxlength="20"
                                    :disabled="form.image ? true : false || shopImageUrl ? true : false">
                                </base-input>
                            </div>
                            <div class="col-6">
                                <base-input name="url" label="URL" v-model="form.url"
                                    :rules="{ custom_required: true, custom_url: true }" placeholder="URL">
                                </base-input>
                            </div>

                            <div class="col-6">
                                <file-input name="image"
                                    :rules="{ custom_required: !form.title && !shopImageUrl, extensions: ['jpg', 'jpeg', 'png'], min_dimensions: [178, 103, 1.7], }"
                                    label="Upload Image" ref="image_input" @change="(files) => form.image = files[0]"
                                    :disabled="form.title ? true : false" :label-info="`Recommended image size 712*412 px. Ratio must be 1.7`"
                                    :uploaded-image="shopImageUrl" @closeImage="() => shopImageUrl = ''"
                                    :form-group-classes="[{'mb-0': getPreviewImageURL}, {'mb-4': !getPreviewImageURL}]"
                                    uploaded-image-css="shop-uploaded-image"
                                />
                                <a href="javascript:void(0)" @click="showPreviewModal" v-if="getPreviewImageURL" class="preview-button d-inline-block mb-4">Show preview</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3 mb-4 cardStyle px-4 pt-4 pb-0">
                <div class="row mt-2">
                    <div class="col-12 col-md-12">
                        <div class="form-group">
                            <base-editor :rules="{ custom_required: form.message_status ? true : false }" name="message_title" v-model="form.message_title" :height="100" :maxlength="15">
                                <template #label>
                                <div class="d-flex justify-content-between mb-2">
                                    <label class="form-control-label mb-0">Message Title</label>
                                    <base-switch class="status-toggle" onText="" offText="" ref="templateSwitcher"
                                        v-model="form.message_status">
                                    </base-switch>
                                </div>
                                </template>
                            </base-editor>
                        </div>
                    </div>
                    <div class="col-12 col-md-12">
                        <base-editor :rules="{ custom_required: form.message_status ? true : false }" name="message" label="Message For Baritastic Shop" v-model="form.message"/>
                    </div>
                </div>
            </div>
            <base-button type="dark-blue" size="xl" class="mb-5" native-type="submit">Update</base-button>
        </form>
        <div class="modals">
            <shop-preview v-if="getPreviewImageURL" :image="getPreviewImageURL" />
        </div>
    </validation-observer>
</template>
  
<script>
import { store } from "@/vuex/store";
import BaseEditor from '@/components/Inputs/BaseEditor.vue';
import ShopPreview from "@/views/Components/SuperAdmin/ManageShop/Preview.vue";


export default {
    props: {
        manageShop: Object,
        submitting: Boolean
    },
    components: {
        BaseEditor,
        ShopPreview
    },
    data() {
        let vm = this;
        let how_often_max = 20;
        let how_often_options = [];
        for (let index = 1; index <= how_often_max; index++) {
            how_often_options.push({
                value: index,
                label: index
            });
        }
        const characterCountMessage = 0;
        const maxCharacterCountMessage = 250;

        return {
            form: {
                title: '',
                url: '',
                image: '',
                message: '',
                message_title: '',
                status: false,
                message_status: false
            },
            how_often_options,
            shopImageUrl: '',
            characterCountMessage,
            maxCharacterCountMessage,
        };
    },
    watch: {
        manageShop(newVal) {
            if (Object.keys(newVal).length) {
                this.form.title = newVal.title;
                this.form.url = newVal.url
                this.form.message_title = newVal.message_title
                this.form.message = newVal.message
                this.form.status = newVal.status
                this.form.message_status = newVal.message_status
                this.shopImageUrl = newVal?.image_url || ''
            }
        },
        'form.title'(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.$refs.image_input.clearFile();
                this.$refs.image_input.$refs.validation_provider.reset();
            }
        },
        'form.image'(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.form.title = '';
            }
        }
    },
    methods: {
        showPreviewModal() {
            this.$store.commit('ManageShopModule/showPreviewModal')
        }  
    },
    computed: {
        getPreviewImageURL() {
            if (this.form.image) {
                return URL.createObjectURL(this.form.image);
            } else {
                return this.shopImageUrl;
            }
        }
    }
};
</script>
  
<style lang="scss">
.preview-button {
    font-size: 12px;
}
.shop-uploaded-image {
    width: 178px;
}
</style>
  